import { Component, OnInit } from '@angular/core';
import { Show } from '../../../models/shows';

@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.scss']
})
export class ShowsComponent implements OnInit {

  public shows: Show[] = [
    {date: '2024-07-26', location: 'Der Freitag in Stukenbrock-Senne – Biergarten in der Ems-Erlebniswelt'},
    {date: '2024-07-27', location: 'Picknick und Musik, Ummeln', cancelled: true},
    {date: '2024-08-10', location: 'Lichterfest, Bodenwerder'},
    {date: '2024-08-11', location: 'Gütersloher Sommer, Gütersloh'},
    {date: '2024-08-25', location: 'Das kleine Fest, Stukenbrock'},
    {date: '2024-12-07', location: 'Spexarder Weihnachtsmarkt, Spexard'},
    {date: '2024-12-14', location: 'Dizzy Dudes Weihnachtsfeier im Blue Fox,<br>Gütersloh'},
    {date: '2025-01-18', location: 'Marienfeld'}
  ];

  constructor() {
  }

  ngOnInit(): void {
    this.orderShowsByDate();
    this.removePastShows();
  }

  private orderShowsByDate(): void {
    this.shows = this.shows.sort((a, b) => {
      return <any>(new Date(a.date)) - <any>(new Date(b.date));
    });
  }

  private removePastShows(): void {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    this.shows = this.shows.filter(s => <any>(new Date(s.date)) >= <any>now);
  }
}
