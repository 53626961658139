import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  title = 'dizzydudes';
  dudes = [
    { image: '../../assets/band-pics/michael.jpg', name: 'Michael Tucker<br>Drums' },
    { image: '../../assets/band-pics/jannik.jpg', name: 'Jannik Regenberg<br>Vocals & Guitar' },
    { image: '../../assets/band-pics/fabi.jpg', name: 'Fabian Kunter<br>Double Bass' },
    { image: '../../assets/band-pics/gregor.jpg', name: 'Gregor Zimmermann<br>Keys' },
    { image: '../../assets/band-pics/dimi.jpg', name: 'Dimitri Thomas<br>Guitar' },
  ];
  social = [
    { image: '../assets/social/yt.svg', url: 'https://www.youtube.com/channel/UCqwZeIyoMeTQTfXGzKmjOhA' },
    { image: '../assets/social/sp.svg', url: 'https://open.spotify.com/artist/75dRBad152cw5qTVwH8tGk' },
    { image: '../assets/social/insta.svg', url: 'https://www.instagram.com/thedizzydudesofficial' },
    { image: '../assets/social/fb.svg', url: 'https://www.facebook.com/dizzydudes' },
  ];
}
