<div fxLayout="column" fxLayoutAlign="space-around center">
  <img fxHide.lt-sm src="assets\logo.png" class="header" height="400px"/>
  <img fxHide.gt-xs src="assets\logo.png" class="header" height="200px"/>
  <iframe width="1280"
          height="720"
          src="https://www.youtube.com/embed/0_LPoBFUVho?autoplay=1&loop=1&autopause=0"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="video">
  </iframe>
  <div fxLayout="row">
    <div class="youtube-abo">Lass uns gerne ein Abo da!</div>
    <div class="g-ytsubscribe"
         data-channelid="UCqwZeIyoMeTQTfXGzKmjOhA"
         data-layout="default"
         data-count="default">
    </div>
  </div>
  <div id="instafeed"></div>
  <div class="small-header" id="shows">Shows</div>
  <app-shows></app-shows>

  <div class="small-header" id="who-we-are">Who we are</div>
  <div class="text">
    Die unübersehbare Lust nach purem Spaß an Musik und Hüftschwung verbindet
    den klassischen Rock'n'Roll mit modernen Melodien zu einer unverkennbaren
    Eigenart. Songs, die zum Mitsingen zwingen, umhüllt von tanzbaren Rhythmen,
    markanten Riffs und einem rollenden Bass bringen die Bude zum wackeln!<br/><br/>
    Überzeugt euch selbst, hört rein und erlebt sie live!
  </div>
  <div fxLayout="row wrap"
       class="dudes-wrapper"
       fxLayoutAlign="space-evenly stretch">
    <div fxFlex.lt-sm="100"
         fxFlex.gt-xs="33"
         fxFlex.gt-md="18"
         fxLayoutAlign="space-between stretch"
         *ngFor="let dude of dudes"
         fxLayout="column"
         class="dudes">
      <div fxHide.lt-sm
           fxFlex
           class="dude-image"
           [ngStyle]="{ 'background-image': 'url(' + dude.image + ')' }">
      </div>
      <div fxHide.gt-xs
           fxFlex
           class="dude-image-mobile"
           [ngStyle]="{ 'background-image': 'url(' + dude.image + ')' }">
      </div>
      <div fxFlex class="dude-text" [innerHTML]="dude.name"></div>
    </div>
  </div>
  <div class="small-header find-us-on" id="media">Find us on</div>
  <div class="social-icons">
    <div fxHide.lt-sm fxFill fxLayout="row" fxLayoutAlign="space-around center">
      <a fxFlex fxFlex="25" *ngFor="let icon of social" [href]="icon.url">
        <div
          class="social-icon"
          [ngStyle]="{ 'background-image': 'url(' + icon.image + ')' }">
        </div
        >
      </a>
    </div>

    <div
      fxHide.gt-xs
      fxFill
      fxLayout="row wrap"
      fxLayoutAlign="space-around center"
    >
      <a fxFlex fxFlex="50" *ngFor="let icon of social" [href]="icon.url">
        <div
          class="social-icon"
          [ngStyle]="{ 'background-image': 'url(' + icon.image + ')' }">
        </div>
      </a>
    </div>
  </div>
  <div class="small-header contact" id="contact">Contact</div>
  <div fxFill class="text">
    <p>
      <b>Musikgalerie Gütersloh</b><br/>
      Phone: 05241-20725<br/>
      Mail: kontakt@dizzydudes.com
    </p>
  </div>
</div>
