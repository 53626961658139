<div class="body-wrap"></div>
<img
  (click)="toggleMenu()"
  *ngIf="showMenu"
  src="../../assets/menu_white_24dp.svg"
  class="menu-button"
/>
<img
  (click)="toggleMenu()"
  *ngIf="!showMenu"
  src="../../assets/menu_black_24dp.svg"
  class="menu-button"
/>
<nav [class.menu-open]="showMenu" id="nav">
  <ul fxLayout="column">
    <a routerLink="" (click)="closeMenu()">
      <li>Home</li>
    </a>
    <a href="#shows" (click)="closeMenu()">
      <li>Shows</li>
    </a>
    <a href="#who-we-are" (click)="closeMenu()">
      <li>Who We Are</li>
    </a>
    <a href="#media" (click)="closeMenu()">
      <li>Media</li>
    </a>
    <a href="#contact" (click)="closeMenu()">
      <li>Contact</li>
    </a>
    <a routerLink="imprint" (click)="closeMenu()">
      <li>Impressum & Datenschutz</li>
    </a>
  </ul>
</nav>

<div class="backdrop" (click)="closeMenu()">
  <router-outlet></router-outlet>

  <div class="footer">
    © The Dizzy Dudes {{ getDate() }}
    <a (click)="scrollTop()" class="scroll-top">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z" fill="#fffa"/>
      </svg>
    </a>
  </div>
</div>
