<div class="slider">
  <div *ngFor="let photo of photos" class="slider__item">
    <button class="slider__item__button" (click)="openDialog(photo)">
      <img src="{{photo}}" alt="Promo foto" class="slider__item__button__img">
    </button>
  </div>
</div>

<dialog #dialog class="dialog">
  <div class="dialog__container">
    <button class="dialog__close" (click)="closeDialog()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="close">
          <path fill="#fff" id="x"
                d="M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z"/>
        </g>
      </svg>
    </button>
    <img src="{{selectedPhoto}}" alt="Promo foto" class="dialog__img">
  </div>
</dialog>
