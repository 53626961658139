import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { ShowsComponent } from './components/home/shows/shows.component';
import { DownloadComponent } from './components/download/download.component';
import { PressMaterialComponent } from './components/press-material/press-material.component';
import { PhotoSliderComponent } from './components/press-material/photo-slider/photo-slider.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ImprintComponent,
    ShowsComponent,
    DownloadComponent,
    PressMaterialComponent,
    PhotoSliderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FlexLayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
