<content fxFill fxLayout="column" fxLayoutAlign="space-around center">
  <img fxHide.lt-sm src="assets\EP-23\Cover_800.jpg" class="download__cover" height="300px"/>
  <img fxHide.gt-xs src="assets\EP-23\Cover_800.jpg" class="download__cover" height="200px"/>
  <div class="small-header download" id="title">EP 2023</div>
  <div class="text">
    Vielen Dank für den Kauf unserer EP!
  </div>
  <div class="text">
    Einige dieser Songs werden wir nicht veröffentlichen, du hast hier also Zugang zu exklusiven Content.
  </div>
  <div class="text">
    Deine Unterstützung bedeutet uns viel und trägt dazu bei, dass wir bald schon neue Songs produzieren und aufnehmen können.
  </div>

  <div class="button-container">
    <a class="download-button" href="/assets/EP-23/DD_Livesession_16BitWAV.zip" download>
      Download All
    </a>
  </div>

  <div class="audio-list">
    <div class="audio" *ngFor="let audio of audioFiles">
      <span class="audio__title">
        {{ audio }}
      </span>
      <audio controls class="audio__player">
        <source src="/assets/EP-23/{{audio}}" type="audio/mp3">
        Your browser does not support audio elements.
      </audio>
    </div>
  </div>

  <div class="social-icons">
    <div fxHide.lt-sm fxFill fxLayout="row" fxLayoutAlign="space-around center">
      <a fxFlex fxFlex="25" *ngFor="let icon of social" [href]="icon.url">
        <div
          class="social-icon"
          [ngStyle]="{ 'background-image': 'url(' + icon.image + ')' }">
        </div
        >
      </a>
    </div>

    <div
      fxHide.gt-xs
      fxFill
      fxLayout="row wrap"
      fxLayoutAlign="space-around center"
    >
      <a fxFlex fxFlex="50" *ngFor="let icon of social" [href]="icon.url">
        <div
          class="social-icon"
          [ngStyle]="{ 'background-image': 'url(' + icon.image + ')' }">
        </div>
      </a>
    </div>
  </div>
</content>
