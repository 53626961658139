import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  public scale: number;
  public social = [
    { image: '../assets/social/yt.svg', url: 'https://www.youtube.com/channel/UCqwZeIyoMeTQTfXGzKmjOhA' },
    { image: '../assets/social/sp.svg', url: 'https://open.spotify.com/artist/75dRBad152cw5qTVwH8tGk' },
    { image: '../assets/social/insta.svg', url: 'https://www.instagram.com/thedizzydudesofficial' },
    { image: '../assets/social/fb.svg', url: 'https://www.facebook.com/dizzydudes' },
  ];
  public audioFiles = [
    '01. Red Lights.mp3',
    '02. Achy Breaky Heart.mp3',
    `03. Sweet Child O' Mine.mp3`,
    '04. Teenage Dirtbag.mp3',
    '05. Twisting The Night Away.mp3',
    '06. Hound Dog.mp3'
  ];
  constructor() { }

  ngOnInit(): void {
    this.scale = Math.round(window.screen.width / 1000 * 100) / 100;
  }
}
