import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-photo-slider',
  templateUrl: './photo-slider.component.html',
  styleUrls: ['./photo-slider.component.scss']
})
export class PhotoSliderComponent implements AfterViewInit {
  @ViewChild('dialog') private dialogEl: ElementRef<HTMLDialogElement>;

  public photos: string[] = [
    '/assets/presse-mat/fotos/IMG_6099.JPG',
    '/assets/presse-mat/fotos/IMG_6104.JPG',
    '/assets/presse-mat/fotos/IMG_6100.JPG',
    '/assets/presse-mat/fotos/IMG_6102.JPG',
    '/assets/presse-mat/fotos/IMG_6101.JPG',
    '/assets/presse-mat/fotos/IMG_6103.JPG',
  ];
  public selectedPhoto: string;

  constructor() {
  }

  ngAfterViewInit(): void {
    this.initCloseOnBackdropClick();
  }

  public openDialog(photoSrc: string): void {
    this.selectedPhoto = photoSrc;
    this.dialogEl.nativeElement.showModal();
  }

  public closeDialog(): void {
    this.dialogEl.nativeElement.close();
    this.selectedPhoto = '';
  }

  public initCloseOnBackdropClick(): void {
    this.dialogEl.nativeElement.addEventListener('click', event => {
      const rect = this.dialogEl.nativeElement.getBoundingClientRect();
      if (this.isOutsideOfEvent(event, rect)) {
        this.closeDialog();
      }
    });
  }

  public isOutsideOfEvent(event, rect: DOMRect): boolean {
    return event.clientY < rect.top || event.clientY > rect.bottom
      || event.clientX < rect.left || event.clientX > rect.right;
  }

}
