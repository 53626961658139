<content fxFill fxLayout="column" fxLayoutAlign="space-around center">
  <img fxHide.lt-sm src="assets\logo.png" class="header" height="300px"/>
  <img fxHide.gt-xs src="assets\logo.png" class="header" height="200px"/>

  <span class="text" style="margin: 32px 0 64px 0;">
    Wir freuen uns, dass Sie sich für ein Gastspiel der Dizzy Dudes bei Ihnen interessieren.<br>
    Hier finden Sie alle wichtigen Informationen und Pressematerialien zur Ansicht und zum Download.<br>
    Machen Sie sich einen Eindruck unserer Bühnenperformance durch Klick auf Wiedergabe im Videoplayer.
  </span>

  <iframe width="1280"
          height="720"
          id="videoDD"
          src="https://www.youtube.com/embed/0_LPoBFUVho?autoplay=1&loop=1&autopause=0"
          title="Youtube Dizzy Dudes Promo Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          class="video">
  </iframe>

  <h2>Technical Rider</h2>
  <span class="text">
    Stageplan und sonstige relevante Informationen.
  </span>
  <a href="/assets/presse-mat/tec-rider_dizzy-dudes_de_2023.pdf" download>Technical Rider 2023</a>

  <h2>Fotos</h2>
  <a class="photo-link" href="/assets/presse-mat/fotos/dd_fotos_23.rar" download>Promo Fotos 2023</a>
  <app-photo-slider></app-photo-slider>

  <h2>Pressetext (lang)</h2>
  <a class="info-link" href="/assets/presse-mat/infotext_dizzy-dudes_de_2021.pdf" download>Info Text Pdf</a>
  <span class="text">
    Die  unübersehbare  Lust  nach  purem  Spaß  an  Musik  und  Hüftschwung
    verbindet  den  klassischen  Rock  ’n’  Roll  mit  modernen  Melodien  zu  einer
    unverkennbaren  Eigenart.  Songs,  die  zum  Mitsingen  zwingen,  umhüllt  von
    tanzbaren Rhythmen, markanten Riffs und einem rollenden Bass bringen die
    Bude zum wackeln!
    Die  Gütersloher  Jungs  spielen  sowohl  eigene  als  auch  gecoverte,  neu
    interpretierte  Songs.  In  den  letzten  zehn  Jahren  wurden  bereits  hunderte
    Bühnen  in  ganz  Deutschland  bespielt.  Die  Dizzy  Dudes  entführen  ihr
    Publikum in eine Reise durch die Zeit von unsterblichen Evergreens bis hin zu
    ganz aktuellen Hits im klaren, puristischen Sound des Rock ’n’ Rolls.
    Kommt vorbei und erlebt sie live!
  </span>

  <h2>Pressetext (kurz)</h2>
  <span class="text">
    Die unübersehbare Lust nach purem Spaß an Musik und Hüftschwung verbindet
    den klassischen Rock'n'Roll mit modernen Melodien zu einer unverkennbaren
    Eigenart. Songs, die zum Mitsingen zwingen, umhüllt von tanzbaren Rhythmen,
    markanten Riffs und einem rollenden Bass bringen die Bude zum wackeln!<br/><br/>
    Überzeugt euch selbst, hört rein und erlebt sie live!
  </span>
</content>
