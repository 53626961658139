import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-press-material',
  templateUrl: './press-material.component.html',
  styleUrls: ['./press-material.component.scss']
})
export class PressMaterialComponent implements OnInit {
  public video;

  constructor() {}

  ngOnInit(): void {
  }

}
