<table class="show-table">
  <tr *ngFor="let show of shows"
      class="show-table__item"
      [class.show-table__item--private]="show.isPrivate"
      [class.show-table__item--cancelled]="show.cancelled">
    <td>
      {{ show.date | date:'dd.MM.yy' }}
    </td>
    <td>
      //
    </td>
    <td class="show-table__item__location">
      <div *ngIf="!show.link" class="show-table__item__location__text" [innerHTML]="show.location">
<!--        <div>-->
<!--          <div *ngIf="show.isPrivate" class="show-table__item__location__private">-->
<!--            Private Show-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <a *ngIf="show.link" href="{{show.link}}" target="_blank" class="show-table__item__location__text"
         [innerHTML]="show.location">
<!--        <div *ngIf="show.isPrivate" class="show-table__item__location__private">-->
<!--          Private Show-->
<!--        </div>-->
      </a>
    </td>
  </tr>
</table>
