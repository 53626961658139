import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { DownloadComponent } from './components/download/download.component';
import { PressMaterialComponent } from './components/press-material/press-material.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'imprint', component: ImprintComponent},
  {path: 'ep23', component: DownloadComponent},
  {path: 'press-kit', component: PressMaterialComponent},
  {path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
